import Vue from 'vue';
import VueRouter from 'vue-router';
import ModesPage from '@/views/ModesPage.vue';
import { hideLoading } from './util-functions/loading-utils';
import { store } from './store';

Vue.use(VueRouter);
const routes = [
  {
    name: 'Sitches',
    path: '/',
    alias: '/shareTarget',
    component: ModesPage,
  },
  {
    name: 'SitchEmbed',
    path: '/#sitch_embed',
    component: ModesPage,
  },
  {
    name: 'GetApp',
    path: '/GetApp',
    component: () => import(/* webpackChunkName: "GetApp" */ '@/views/GetApp.vue'),
  },
  {
    name: 'WhatsNew',
    path: '/WhatsNew',
    component: () => import(/* webpackChunkName: "WhatsNew" */ '@/views/WhatsNew.vue'),
  },
  {
    name: 'Deposits',
    path: '/Deposits',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Deposits.vue'),
  },
  {
    name: 'Account',
    path: '/Account',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Account.vue'),
  },
  {
    path: '/Account/:paymentStatus',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Account.vue'),
    props: true,
  },
  {
    name: 'Notifications',
    path: '/Notifications',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Notifications.vue'),
    props: true,
  },
  {
    name: 'Shortcuts',
    path: '/Shortcuts',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Shortcuts.vue'),
  },
  {
    name: 'Wearables',
    path: '/Wearables',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Wearables.vue'),
  },
  {
    name: 'ThemeForm',
    path: '/ThemeForm',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/ThemeForm.vue'),
  },
  {
    name: 'Themes',
    path: '/Themes',
    component: () => import(/* webpackChunkName: "Themes" */ '@/views/Themes.vue'),
  },
  {
    name: 'SitchDevices',
    path: '/SitchDevices',
    component: () => import(/* webpackChunkName: "SitchDevice" */ '@/views/SitchDevices.vue'),
  },
  {
    name: 'SitchPremium',
    path: '/SitchPremium',
    component: () => import(/* webpackChunkName: "SitchPremium" */ '@/views/SitchPremium.vue'),
  },
  {
    name: 'QR',
    path: '/QR',
    component: () => import(/* webpackChunkName: "QR" */ '@/views/QR.vue'),
  },
  {
    name: 'Help',
    path: '/Help',
    component: () => import(/* webpackChunkName: "HelpPages" */ '@/views/Help.vue'),
  },
  {
    name: 'FAQ',
    path: '/FAQ',
    component: () => import(/* webpackChunkName: "HelpPages" */ '@/views/help-views/FAQ.vue'),
  },
  {
    name: 'Videos',
    path: '/Videos',
    component: () => import(/* webpackChunkName: "HelpPages" */ '@/views/help-views/Videos.vue'),
  },
  {
    name: 'Payments',
    path: '/Payments',
    component: () => import(/* webpackChunkName: "Data" */ '@/views/Payments.vue'),
  },
  {
    name: 'AffiliateSales',
    path: '/AffiliateSales',
    component: () => import(/* webpackChunkName: "Data" */ '@/views/AffiliateSales.vue'),
  },
  {
    name: 'Analytics',
    path: '/Analytics',
    component: () => import(/* webpackChunkName: "Data" */ '@/views/Analytics.vue'),
  },
  {
    name: 'CustomForms',
    path: '/CustomForms',
    component: () => import(/* webpackChunkName: "Data" */ '@/views/CustomForms.vue'),
  },
  {
    name: 'Bookings',
    path: '/Bookings',
    component: () => import(/* webpackChunkName: "Data" */ '@/views/Bookings.vue'),
  },
  {
    name: 'SitchForm',
    path: '/SitchForm',
    component: () => import(/* webpackChunkName: "ModeForm" */ '@/views/ModeForm.vue'),
  },
  {
    name: 'SitchCam',
    path: '/SitchCam',
    component: () => import(/* webpackChunkName: "ModeForm" */ '@/views/ModeForm.vue'),
    props: { isForSitchCam: true },
  },
  {
    name: 'Teams',
    path: '/Teams',
    component: () => import(/* webpackChunkName: "QR" */ '@/views/Teams.vue'),
  },
  {
    name: 'TermsOfService',
    path: '/TermsOfService',
    component: () => import(/* webpackChunkName: "TermsOfService" */ '@/views/TermsOfService.vue'),
  },
  {
    name: 'PrivacyPolicy',
    path: '/PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy.vue'),
  },
  {
    name: 'Login',
    path: '/Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
  },
  {
    name: 'CSVImports',
    path: '/CSVImports',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/CSVImports.vue'),
  },  
  // Catch all.
  {
    path: '*',
    component: ModesPage,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

function hasQueryParams(route: any) {
  return !!Object.keys(route.query).length;
}

router.beforeEach(async (to, from, next) => {
  if (to.name && !['Login'].includes(to.name) && !store.state.isLoggedIn && !store.state.initialLoadIsHappening) {
    next({ name: 'Login', query: from.query });
    // Don't allow going back to the Login screen.
  } else if (!(to.name && ['Login'].includes(to.name) && store.state.isLoggedIn)) {
    // Handling embedded sitch hashes.
    if (to.fullPath === '/#affiliate') {
      next({ name: 'Account', query: from.query });
      return;
    }
    if (to.fullPath === '/#store') {
      next({ name: 'SitchDevices', query: from.query });
      return;
    }
    if (to.fullPath === '/#contact') {
      next({ name: 'Help', query: from.query });
      return;
    }

    if (window.location.origin === 'http://localhost:8080' && !hasQueryParams(to) && hasQueryParams(from)) {
      next({ name: to.name || '', query: from.query });
      return;
    }
    next();
  }
});

router.afterEach(() => {
  hideLoading(true);
});

export default router;
