import { endpoints, TEAM_MEMBERS_MAX } from '@/constants';
import { eSignInErrors } from '@/enums';
import { getEmptyUser, store } from '@/store';
import { User } from 'firebase/auth';
import { collection, doc, DocumentSnapshot, getDoc, getDocs, onSnapshot, setDoc, Timestamp, writeBatch } from 'firebase/firestore';
import Vue from 'vue';
import { currFirestore, fbAuth, isProductionEnv, rootComponent, standardApiFetch } from './initialization-utils';
import { t } from './language-utils';
import { hideLoading, showLoading } from './loading-utils';
import { showError } from './misc-firestore-utils';
import { getParameterByName, localStoreGet, localStoreRemove, localStoreSet } from './misc-utils';
import { showConfirmation, showNotice, showPrompt } from './notice-utils';
import { getSubscriptionData } from './sitch-premium-utils';
import { getUserDoc, getUserModeGatewayDoc, leaveTeam, updateUserDoc, updateUserLocal, updateUserModeGatewayDoc, updateUserModeGatewayLocal } from './user-utils';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { setUpNotifications } from './notification-utils';

export let signInComplete = false;

export const onUserSignIn = (user: User | null) => {
  if (user) {
    const isAdmin = isProductionEnv ? user.uid === '6mP6YhIGkJdsZMQTRjCRgzlhxsg2' : user.uid === 'LMnlgmyZHOQUEngEPyZByVR5nzO2';
    store.commit('isAdmin', isAdmin);
    if (isAdmin && getParameterByName('uid')) {
      setUpForSignedInAuthUser(user, getParameterByName('uid'));
    } else {
      setUpForSignedInAuthUser(user);
    }
  } else {
    store.commit('isLoggedIn', false);
  }
};

export const emailIsVerified = () => {
  const firebaseUser = store.state.firebaseUser;
  if (firebaseUser) {
    const exceptions = ['@umaryland.edu', '@sitch.cards', '@sitch.app'];
    return exceptions.some((exception) => firebaseUser.email?.includes(exception)) ? true : firebaseUser.emailVerified;
  }
  return false;
};

export const setUpForSignedInAuthUser = async (user: User, uidOverride = '') => {
  const userId = uidOverride || user.uid;
  store.commit('userId', userId);
  store.commit('firebaseUser', user);

  function onSignInComplete() {
    hideLoading(true);

    signInComplete = true;

    getSubscriptionData();

    Vue.nextTick(() => {
      store.commit('isLoggedIn', true);
      const settings = store.state.currUser.settings;

      const atLeastOneNativePushTicked = settings.getNativePushNotificationsForBookings || settings.getNativePushNotificationsForCustomForms || settings.getNativePushNotificationsForPayments;
      if (Capacitor.isNativePlatform()) {
        if (atLeastOneNativePushTicked) {
          setUpNotifications();
        }
      } else {
        const atLeastOneWebPushTicked = settings.getWebPushNotificationsForBookings || settings.getWebPushNotificationsForCustomForms || settings.getWebPushNotificationsForPayments;
        if (atLeastOneWebPushTicked) {
          setUpNotifications();
        }
      }
    });
  }

  if (!Capacitor.isNativePlatform()) {
    onSnapshot(doc(currFirestore, 'systemManagementDocs', 'systemData'), (doc: DocumentSnapshot) => {
      if (doc.exists()) {
        const version: string = (doc.data() as any).version;
        const versionChangeNotes: string = (doc.data() as any).notes;
        const currVersion: string | null = localStoreGet('currentSitchVersion');
        rootComponent.versionChangeNotes = versionChangeNotes;
        localStoreSet('newestSitchVersion', version.toString());
        if (currVersion === null) {
          // First time opening app.
          localStoreSet('currentSitchVersion', version.toString());
        } else if (currVersion !== version) {
          // Need to update.
          rootComponent.showUpdateOption = true;
          rootComponent.showUpdateDialog = true;
        } else {
          // Update just happened.
          localStoreSet('currentSitchVersion', version.toString());
        }
      }
    });
  }

  async function postSignInQueryParamChecks() {
    const genericId = getParameterByName('g') || localStoreGet('genericCodeForSignUp');
    let teamInviteIds = getParameterByName('iv');
    let isAutoInvite = false;

    if (localStoreGet('genericCodeForSignUp') && emailIsVerified()) {
      localStoreRemove('genericCodeForSignUp');
    }

    const checkForTeamInvite = () => {
      if (teamInviteIds) {
        const teamCreatorId = teamInviteIds.split(':')[0];
        const teamId = teamInviteIds.split(':')[1];
        const currUser = store.state.currUser;

        if (teamCreatorId === store.state.userId) {
          showPrompt(t.cannotJoinOwnTeam);
          return;
        }

        if (currUser.joinedTeamsRefs.map((ref) => ref.id).includes(teamId)) {
          showPrompt(t.youHaveAlreadyJoinedThisTeam);
          return;
        }

        const teamDoc = doc(currFirestore, 'users', teamCreatorId, 'teams', teamId);
        getDoc(teamDoc)
          .then((docSnap) => {
            if (docSnap.exists()) {
              const teamData = docSnap.data() as Team;
              if (teamData.teamOwnerId === currUser.docId) {
                showPrompt(t.cannotJoinYourOwnTeam);
                return;
              }

              // Team member max exceptions
              const sitchProdTeamOwner = 'KvE45eNAmlUhk5y7xJnbAJM8bP02'; // prod@sitch.app
              const fremontTeamOwner = 'ee8KEWSDSAMFqnwcLhiHlQI42vf2'; // jrunning@fremontmotors.com

              if (teamData.memberCount >= TEAM_MEMBERS_MAX && ![sitchProdTeamOwner, fremontTeamOwner].includes(teamCreatorId)) {
                // Sitch prod teams can be infinite in size.
                showPrompt(t.teamAtMax.supplant([teamData.name, TEAM_MEMBERS_MAX]));
                return;
              }

              const joinTeam = () => {
                updateUserDoc({
                  joinedTeamsRefs: [...currUser.joinedTeamsRefs, docSnap.ref],
                });
                const newTeamMember: TeamMember = {
                  userId: currUser.docId,
                  displayName: currUser.displayName,
                  email: currUser.email,
                  dateCreated: Timestamp.fromMillis(Date.now()),
                  dateUpdated: Timestamp.fromMillis(Date.now()),
                };
                const teamMemberDoc = doc(currFirestore, teamDoc.path, 'members', currUser.docId);
                setDoc(teamMemberDoc, newTeamMember);

                const teamWasCreatedByThisUsersCreator = teamData.teamOwnerId === currUser.wasCreatedBy;

                if (teamData.assignedThemeId && teamWasCreatedByThisUsersCreator) {
                  const teamOwnerGatewayRef = doc(currFirestore, 'publicUserModeGateways', teamData.teamOwnerId);
                  updateUserModeGatewayDoc({
                    teamOwnerGatewayRef,
                    assignedTeamThemeId: teamData.assignedThemeId,
                    dateUpdated: Timestamp.fromMillis(Date.now()),
                  })
                }

                getJoinedTeamModes();
              };

              if (isAutoInvite) {
                joinTeam();
                showPrompt(t.youHaveJoinedATeam.supplant([teamData.name]));
              } else {
                showConfirmation(t?.doYouWantToJoinThisTeam.supplant([teamData.name]), joinTeam);
              }
            } else {
              showError(t.teamDeleted);
            }
          })
          .catch((error: any) => {
            if (error.message === 'Missing or insufficient permissions.') {
              showError(t.notWhitelisted);
            } else {
              showError(`Could not get the team data.`, error, true);
            }
            hideLoading();
          });
      }
    };

    if (genericId && emailIsVerified()) {
      await getDoc(doc(currFirestore, 'genericIdToUserIdMappings', getParameterByName('g'))).then((docSnap) => {
        if (docSnap.exists()) {
          const genericIdData = docSnap.data() as GenericIdData;
          if (genericIdData.autoJoinTeamId) {
            teamInviteIds = genericIdData.autoJoinTeamId;
            isAutoInvite = true;
          }
        }
      });

      showConfirmation(t?.doYouWantToLinkThisDevice, () => {
        standardApiFetch(endpoints.activateSitchLink, {
          activationId: genericId,
          userId,
        })
          .then((response) => {
            const newGenericIdsBoundToThisAccount = {
              ...store.state.currUser.genericIdsBoundToThisAccount,
              [genericId]: response.successfulResponse.newGenericIdData,
            };
            updateUserLocal({
              genericIdsBoundToThisAccount: newGenericIdsBoundToThisAccount,
              dateUpdated: Timestamp.fromMillis(Date.now()),
            });
            store.commit('currUserModeGateway', {
              ...store.state.currUserModeGateway,
              isSitchLinkActivated: true,
              dateUpdated: Timestamp.fromMillis(Date.now()),
            });
            showNotice(t.yourDeviceHasBeenLinked);
          })
          .catch((response) => {
            switch (response.errorMessage) {
              case eSignInErrors.databaseError:
                showError(t.cannotLinkAccountDatabaseError);
                break;
              case eSignInErrors.alreadyActivated:
                showError(t.cannotLinkAccountAlreadyActivated);
                break;
              case eSignInErrors.idNotValid:
                showError(t.cannotLinkAccountIdNotValid);
                break;
            }
          })
          .finally(() => {
            checkForTeamInvite();
          });
      });
    } else {
      checkForTeamInvite();
    }
  }

  const getJoinedTeamModes = (): Promise<void> => {
    // If we're a part of any teams, get the modes for those teams and load them in.
    const joinedTeamsRefs = [...store.state.currUser.joinedTeamsRefs];
    if (joinedTeamsRefs?.length) {
      const teamPromises: Promise<any>[] = [];
      const memberPromises: Promise<any>[] = [];
      const joinedTeamsObjects: { [teamModeId: string]: Team } = {};
      joinedTeamsRefs.forEach((teamRef) => {
        const teamDocPromise = getDoc(teamRef)
          .then((teamDocSnap) => {
            const team = teamDocSnap.data() as Team;

            // Make sure we weren't removed from the team by checking if we're still in the members collection.
            const memberDoc = doc(currFirestore, teamDocSnap.ref.path, 'members', store.state.userId);
            const memberDocPromise = getDoc(memberDoc)
              .then((memberDocSnap) => {
                if (memberDocSnap.exists()) {
                  joinedTeamsObjects[team.docId] = team;
                } else {
                  leaveTeam(teamRef.id);
                  showError(t.youWereRemovedFromATeam);
                }
              })
              .catch((error) => {
                showError(`Could not retrieve a team member doc.`, error, true);
              });
            memberPromises.push(memberDocPromise);
          })
          .catch((error) => {
            // If this is the case that means we are not longer whitelisted.
            if (error.message === 'Missing or insufficient permissions.') {
              leaveTeam(teamRef.id);
              showError(t.youWereRemovedFromATeam);
            }
          });
        teamPromises.push(teamDocPromise);
      });
      // Wait for all for all the team promises to finish so we have all the member promises, then wait for those to do the final update.
      return Promise.all(teamPromises).then(() => {
        Promise.all(memberPromises).then(() => {
          store.commit('joinedTeamsObjects', joinedTeamsObjects);
        });
      });
    }
    return Promise.resolve();
  };

  const userGatewayDoc = getUserModeGatewayDoc();
  const promises: Promise<any>[] = [];
  const modes: ModesMap = {};

  promises.push(
    getDoc(getUserDoc())
      .then((userDocSnap) => {
        if (userDocSnap.exists()) {
          // Signing in as an existing user.
          const userData = userDocSnap.data() as PlatformUser;
          updateUserLocal(userData);
          getJoinedTeamModes();
        } else {
          // Creating a new user.
          const newUserData: PlatformUser = {
            ...getEmptyUser(),
            dateCreated: Timestamp.fromMillis(Date.now()),
            dateUpdated: Timestamp.fromMillis(Date.now()),
            docId: userId,
            displayName: user.displayName || store.state.dataForEmailSignUp.displayName || '',
            email: user.email || '',
          };
          const batch = writeBatch(currFirestore);
          updateUserLocal(newUserData);
          getJoinedTeamModes();
          batch.set(userGatewayDoc, {
            dateCreated: Timestamp.fromMillis(Date.now()),
            dateUpdated: Timestamp.fromMillis(Date.now()),
          });
          batch.set(getUserDoc(), newUserData);

          batch
            .commit()
            .then(() => {
              postSignInQueryParamChecks();
            })
            .catch((error: any) => {
              signOutAccount();
              showError(`Could not create user account:`, error, true);
            })
            .finally(() => {
              hideLoading();
            });
        }
      })
      .catch((error: any) => {
        signOutAccount();
        showError(`Could not get user account:`, error, true);
        hideLoading();
      })
  );

  promises.push(
    getDoc(userGatewayDoc)
      .then((modeGatewayDocSnap) => {
        if (modeGatewayDocSnap.exists()) {
          const publicUserModeGateway = modeGatewayDocSnap.data() as PublicUserModeGateway;
          updateUserModeGatewayLocal(publicUserModeGateway);
        }
        postSignInQueryParamChecks();
      })
      .catch((err: any) => {
        showError(`Could not get modes for user: ${err?.message}`, err, true);
        hideLoading();
      })
  );

  promises.push(
    getDocs(collection(currFirestore, userGatewayDoc.path, 'modes')).then((querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach((modeDocSnap) => {
          const mode: AnyMode = modeDocSnap.data() as AnyMode;
          modes[mode.docId] = mode;
        });
        store.commit('modes', modes);
      }
    })
  );

  Promise.all(promises)
    .then(() => {
      onSignInComplete();
    })
    .catch(() => {
      hideLoading();
    });
};

export const signOutAccount = (): Promise<void[]> => {
  showLoading();
  const promises = [];
  // Sign out on the web layer.
  promises.push(
    fbAuth
      .signOut()
      .then(() => {
        store.commit('reset');
      })
      .catch((error: any) => {
        showError(`Could not sign out of web.`, error, true);
      })
  );
  // Sign out on the native layer.
  promises.push(
    FirebaseAuthentication.signOut()
      .then(() => {
        store.commit('reset');
      })
      .catch((error: any) => {
        showError(`Could not sign out of native.`, error, true);
      })
  );
  return Promise.all(promises).finally(() => {
    hideLoading();
  });
};
