
import Vue from 'vue';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { showConfirmation } from '@/util-functions/notice-utils';
import { hideLoading, showLoading } from '@/util-functions/loading-utils';
import { parse } from 'papaparse';
import { fbAuth, standardApiFetch } from '@/util-functions/initialization-utils';
import { endpoints } from '@/constants';

export default Vue.extend({
  data(): {
    uploadedCreationCsvFile: Blob | null;
    showProfileModeCreationFromCsvDialog: boolean;
  } {
    return {
      uploadedCreationCsvFile: null,
      showProfileModeCreationFromCsvDialog: false,
    };
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.state.isAdmin;
    },
  },
  methods: {
    onAddAccountsFromCsvClick() {
      if (!this.isAdmin && !this.$store.state?.currUserModeGateway.hasPermanentPremium) {
        showError(t.thisIsAnEnterpriseTierFeature);
        return;
      }
      this.showProfileModeCreationFromCsvDialog = true;
    },
    createProfileModes() {
      interface newProfileMode extends ProfileMode {
        defaultPassword: string;
      }

      if (this.uploadedCreationCsvFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
          let csvString = event.target?.result as string;

          if (!csvString) {
            showError(`No CSV provided`);
            return;
          }

          const currUser = fbAuth.currentUser;

          if (!currUser) {
            showError(`No logged in user`, null, true);
            return;
          }

          showLoading();

          parse(csvString, {
            header: true,
            transform: function (s: string) {
              return s.trim();
            },
            skipEmptyLines: true,
            complete: (results: any) => {
              let profileModesToCreate = results.data.filter((newProfileMode: newProfileMode) => {
                return newProfileMode.displayName;
              });

              if (profileModesToCreate.length === 0) {
                showError(t.csvIsMalformed);
                hideLoading();
                return;
              }

              currUser
                .getIdToken(/* forceRefresh */ true)
                .then((idToken) => {
                  standardApiFetch(endpoints.createMultipleProfileModes, {
                    profileModesToCreate,
                    userId: this.$store.state.userId,
                    idToken,
                  })
                    .then(async () => {
                      if (profileModesToCreate.length !== results.data.length) {
                        showError(t.someEntriesWereSkipped);
                      }

                      this.showProfileModeCreationFromCsvDialog = false;

                      showConfirmation(t.profileModesSuccessfullyCreated);

                      hideLoading(true);
                    })
                    .catch((error) => {
                      hideLoading();
                      showError(`Could not create the accounts.`, error, true);
                    });
                })
                .catch((error) => {
                  hideLoading();
                  showError(`Could not get user token to make request`, error, true);
                });
            },
          });
        };
        reader.readAsText(this.uploadedCreationCsvFile);
      }
    },
  },
});
